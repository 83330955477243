import React from 'react'
import style from '../assets/scss/Terminos.module.css'

function Terminos() {

  let date = new Date()

  const termsData = [
    {
      title: 'Aceptación de los Términos',
      data: 'Al contratar nuestros servicios de creación de sitios web estáticos, aceptas y te comprometes a cumplir los siguientes términos y condiciones.'
    },
    {
      title: 'Alcance del Servicio',
      data: 'Nos comprometemos a diseñar y desarrollar un sitio web estático personalizado según tus requisitos y especificaciones proporcionadas durante el proceso de contratación. El servicio incluye la creación de las páginas web acordadas, la selección de elementos gráficos apropiados y la optimización del sitio para un rendimiento óptimo.'
    },
    {
      title: 'Contenido y Derechos de Autor',
      data: 'Eres responsable de proporcionar todo el contenido, textos, imágenes, logotipos y cualquier otro material necesario para el desarrollo del sitio web. Debes asegurarte de tener los derechos legales para utilizar y publicar dicho contenido. Nos reservamos el derecho de rechazar cualquier material que consideremos inapropiado o infractor de los derechos de autor.'
    },
    {
      title: 'Pago y Facturación',
      data: 'Los detalles de pago, tarifas y plazos serán acordados antes del inicio del proyecto. Nos reservamos el derecho de suspender o retrasar el desarrollo del sitio web en caso de incumplimiento de los pagos acordados. Todos los pagos deben realizarse según los términos acordados y son no reembolsables una vez iniciado el proyecto.'
    },
    {
      title: 'Entrega y Propiedad',
      data: 'El sitio web completado se entregará una vez que se haya realizado el pago total. Una vez entregado, los derechos de propiedad del sitio web pasarán al cliente. Sin embargo, nos reservamos el derecho de incluir una referencia o enlace a nuestro sitio web en el pie de página del sitio desarrollado, con el fin de promocionar nuestros servicios.'
    },
    {
      title: 'Mantenimiento y Actualizaciones',
      data: 'Nuestro servicio no incluye mantenimiento continuo ni actualizaciones futuras del sitio web, a menos que se acuerde lo contrario. Si deseas servicios adicionales de mantenimiento o actualizaciones, podemos proporcionarte un acuerdo por separado.'
    },
    {
      title: 'Responsabilidad',
      data: 'Nos esforzamos por brindar servicios de alta calidad y sin errores. Sin embargo, no nos hacemos responsables de cualquier daño directo, indirecto o consecuente que pueda surgir del uso o la imposibilidad de uso del sitio web desarrollado, incluyendo pérdida de datos o interrupción del negocio.'
    },
    {
      title: 'Modificaciones de los Términos y Condiciones',
      data: 'Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso. Cualquier cambio entrará en vigencia una vez publicado en nuestro sitio web. Se te notificará sobre cualquier cambio importante en los términos y condiciones.'
    }
  ]

  return (
    <main className={style.main}>
      <h3>
        El Mirador
        <br />
        <br />
        Términos y Condiciones - Servicio de Creación de Sitios Web Estáticos
      </h3>
      <br />
      <ul>
        {
          termsData.map((data, i) => {
            return (
              <div key={i}>
                <li>
                  <span style={{ textDecoration: 'underline' }}>{data.title}</span> <br />
                  {data.data}
                </li>
                <br />
              </div>
            )
          })
        }
      </ul>
      <p>
        Estos términos y condiciones son un acuerdo legal entre [El Mirador] y el cliente que contrata nuestros servicios de creación de sitios web estáticos. Al aceptar nuestros servicios, confirmas que has leído, entendido y aceptado estos términos y condiciones en su totalidad.
      </p>
      <br />
      <p>
        Fecha de entrada en vigor: 17/05/2023
      </p>
      <br />
      <p>
        Si tienes alguna pregunta o inquietud con respecto a estos términos y condiciones, por favor contáctanos.
      </p>
      <br />
      <p>
        El Mirador <br />
        <a href="mailto:contacto@elmiradorweb.com.ar" style={{ textDecoration: 'none', color: 'white' }}>contacto@elmiradorweb.com.ar</a>
      </p>
    </main>
  )
}

export default Terminos
