import React from 'react'
import style from '../assets/scss/Error.module.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

function Error() {

  var headTitle = document.head

  var newHead = document.createElement('title')
  newHead.innerHTML = 'Error 404 '

  var oldHead = headTitle.querySelector('title')
  oldHead ? headTitle.removeChild(oldHead) : headTitle.appendChild(newHead)

  return (
    <main className={style.main}>
      <FontAwesomeIcon icon={faExclamationTriangle} className={style.icon} fontSize={170} />
      <h1>404</h1>
      <h2>Página no encontrada</h2>
      <p>
        Lamentablemente, la URL solicitada no existe en nuestro servidor. Puede haber sido eliminada, renombrada o puede que hayas ingresado la dirección incorrecta.
        Te recomendamos verificar la URL e intentarlo nuevamente. Si crees que esto es un error del sitio web, por favor contáctanos para que podamos solucionarlo.
      </p>
      <Link to="/" className={style.back}> Volver al inicio </Link>
    </main>
  )
}

export default Error