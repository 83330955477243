import React from 'react'
import style from '../assets/scss/MiddleContent.module.css'
import LandPageIllustration from '../assets/images/WebDeveloperMonochromatic.svg'

function MiddleContent() {
  return (
    <section className={style.middleSection}>
      <img src={LandPageIllustration} alt="landing-page-illustration" width='100%' />
      <div className={style.middle}>
        <article className={style.middle__info}>
          <p>
            Nuestro servicio de desarrollo de landing pages (o páginas básicas) puede ayudarte a crear una página única, personalizada y efectiva que te permita convertir a los visitantes en potenciales clientes. Con un diseño atractivo, contenido persuasivo y una estructura clara, una landing page bien desarrollada puede ser una herramienta poderosa para ayudarte a alcanzar tus objetivos de negocio en línea.
          </p>
        </article>
        <hr />
        <div className={style.middle__faq}>
          <p><span>¿Tenés dudas? ¿No tenés ni idea que es una landing page? ¿Qué es un SEO, se come?</span> No dudes y hacé click en el botón de abajo creado con las últimas tecnologías de desarrollo, y diseñado por un equipo expertos en el tema.</p>
        </div>
        <div className={style.middle__btn}>
          <button className={style.main__btn}>
            <a href='/about/more' className={style.btn}>Click acá!</a>
          </button>
        </div>
      </div>
    </section>
  )
}

export default MiddleContent