import React from 'react'
import style from '../assets/scss/Head.module.css'
import logo from '../assets/images/mirador-logo.svg'

function Head() {
  return (
    <section className={style.main} id='head'>
      <div className={style.main__hero}>
        <img src={logo} alt="illustration-hero" className={style.hero__img} />
      </div>
      <div className={style.main__text}>
        <h1 className={style.text__title}>Crea una <span>presencia</span> en línea <span>única</span>, efectiva y para todos los dispositivos.</h1>
        <h2 className={style.text__subtitle}>Servicio completo de desarrollo web con enfoque en el diseño personalizado y la optimización para motores de búsqueda.</h2>
        <a href="mailto:contacto@elmiradorweb.com.ar" className={style.text__btn}>Empezá ahora</a>
      </div>
    </section>
  )
}

export default Head