import './assets/css/reset.css';
import { Routes, Route } from 'react-router-dom';
import style from './assets/scss/App.module.css';
import Nav from './components/Nav';
import Main from './pages/Main';
import About from './pages/About';
import AboutMore from './pages/AboutMore';
import Services from './pages/Services';
import Terminos from './pages/Terminos';
import Error from './pages/Error';

function App() {
  return (
    <div className={style.App}>
      <Nav />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Error />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/more" element={<AboutMore />} />
        <Route path="/services" element={<Services />} />
        <Route path="/terminos-y-condiciones" element={<Terminos />} />
      </Routes>
    </div>
  );
}

export default App;
