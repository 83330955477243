import React from 'react'
import style from '../assets/scss/AboutMore.module.css'
import basicPageIsoSVG from '../assets/images/OnlineCampaignIsometric.svg'
import landpageIsoSVG from '../assets/images/LandingPageIsometric.svg'
import actionIsoSVG from '../assets/images/ClickHereIsometric.svg'
import seoIsoSVG from '../assets/images/seoIsometric.svg'
import hostingIsoSVG from '../assets/images/DataHostingIsometric.svg'
import domainIsoSVG from '../assets/images/NetworkIsometric.svg'
import phoneIsoSVG from '../assets/images/PhoneMaintenanceIsometric.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons'

function AboutMore() {

  var headTitle = document.head

  var newHead = document.createElement('title')
  newHead.innerHTML = 'El Mirador - Saber Más'

  var oldHead = headTitle.querySelector('title')
  oldHead ? headTitle.removeChild(oldHead) : headTitle.appendChild(newHead)

  return (
    <section className={style.section}>
      <div className={style.bg}></div>
      <div className={style.section__title}>
        <h5>
          ¿Tenés dudas? ¿No tenés ni idea que es una landing page? ¿Qué es un SEO, se come?
        </h5>
        <p>
          No te preocupes que estás en el lugar correcto mi querido amigo. En esta sección vas a encontrar todo lo que necesitás saber sobre Landing Pages, páginas básicas, SEO, hosting, dominios, tecnologías y mucho más.
        </p>
      </div>
      <div>
        <img src={basicPageIsoSVG} alt="basic-page-illustration" />
        <h3>
          Esperen, primero que nada... ¿A que se refieren con "Página básica"?
        </h3>
        <p id='basicpage'>
          Las páginas básicas son <span>elementos fundamentales</span> de cualquier sitio web. Estas páginas proporcionan información esencial sobre tu <span>empresa</span>, <span>productos</span> o <span>servicios</span>. A diferencia de una landing page, que está diseñada para convertir a los visitantes en clientes potenciales (más abajo lo explicamos), las <span>páginas básicas</span> están diseñadas para <span>proporcionar información</span> y <span>ayudar</span> a los <span>visitantes</span> a <span>navegar</span> por tu sitio web.
          <br />
          <br />
          Una página básica común incluye:
        </p>
        <ul>
          <li>
            <span>Página de inicio</span>: La página principal del sitio web que da la bienvenida a los visitantes y ofrece una visión general de tu negocio.
          </li>
          <li>
            <span>Página de servicios</span>: Aquí puedes describir detalladamente los servicios que ofreces, resaltando los beneficios y características clave.
          </li>
          <li>
            <span>Página de productos</span>: Si vendes productos, esta página muestra tu catálogo con descripciones, imágenes y detalles de los productos.
          </li>
          <li>
            <span>Página de contacto</span>: Una página que permite a los visitantes ponerse en contacto contigo, proporcionando información de contacto como dirección de correo electrónico, número de teléfono o un formulario de contacto.
          </li>
        </ul>
        <br />
        <p>
          Las páginas básicas son <span>esenciales</span> para <span>brindar información</span> clara y concisa sobre tu <span>negocio</span> y permitir que los <span>visitantes</span> naveguen y se <span>involucren</span> con tu contenido. Aunque se llamen "páginas básicas", <span>no subestimes su importancia</span>, ya que son la columna vertebral de tu sitio web y <span>proporcionan una base sólida</span> para tu presencia en línea.
        </p>
      </div>
      <div>
        <img src={landpageIsoSVG} alt="land-page-illustration" />
        <h3>
          Y entonces, ¿Qué es una landing page?!¡
        </h3>
        <p id='landpages'>
          Una <span><b>landing page</b></span>, también conocida como página de destino, es un tipo de página web <span><b>diseñada específicamente</b></span> para <span><b>captar la atención</b></span> de los visitantes y convertirlos en <span><b>clientes potenciales</b></span> o realizar una acción <span><b>deseada</b></span>. Una landing page efectiva se centra en un solo objetivo y está diseñada para maximizar las conversiones.
        </p>
        <br />
        <p>
          Estas suelen tener un <span><b>diseño limpio</b></span> y <span><b>atractivo</b></span>, con contenido <span><b>relevante y persuasivo</b></span> que guía a los visitantes hacia una <span><b>Call to Action</b></span> específica. Pueden incluir elementos como <span><b>formularios</b></span> de suscripción, <span><b>botones</b></span> de compra, <span><b>testimonios de clientes</b></span>, características del producto o servicio, y <span><b>beneficios destacados</b></span>. El <span><b>objetivo</b></span> final de una landing page es <span><b>persuadir</b></span> a los visitantes para que realicen la <span><b>acción deseada</b></span>, como realizar una <span><b>compra</b></span>, <span><b>suscribirse</b></span> a un boletín informativo o completar un <span><b>formulario</b></span> de contacto.
        </p>
      </div>
      <div>
        <img src={actionIsoSVG} alt="call-to-action-illustration" />
        <h3>
          Pará pará pará... ¡¿Call to qué?!🥶
        </h3>
        <p id='ctas'>
          Call to Action <span><b>(CTA)</b></span> o Llamada a la Acción es un <span><b>elemento visual</b></span> o textual en una página web o publicación que <span><b>invita</b></span> a los usuarios a <span><b>realizar una acción específica</b></span>. Su <span><b>objetivo principal</b></span> es <span><b>guiar</b></span> y <span><b>motivar</b></span> a los visitantes a tomar <span><b>medidas</b></span> concretas que los <span><b>acerquen</b></span> a convertirse en <span>clientes</span>, <span><b>suscriptores</b></span> o <span><b>participantes</b></span> en una determinada actividad.
        </p>
        <br />
        <p>
          Las CTAs suelen presentarse en forma de <span><b>botones</b></span>, <span><b>enlaces</b></span> o <span><b>mensajes destacados</b></span> que llaman la atención del usuario y le indican claramente qué acción se espera que realice. Algunos ejemplos comunes de CTAs incluyen:
        </p>
        <ul>
          <li>"Regístrate ahora"</li>
          <li>"Suscríbete"</li>
          <li>"Compra ahora"</li>
          <li>"Descarga nuestro ebook"</li>
          <li>"Solicita una cotización"</li>
          <li>"Contáctanos"</li>
          <li>"Explora nuestras ofertas"</li>
        </ul>
        <br />
        <p>
          Las CTAs <span>deben ser claras</span>, <span>directas</span> y <span>convincentes</span>, utilizando un <span>lenguaje persuasivo</span> que motive al usuario a actuar. Además, es importante que las CTAs estén <span>ubicadas estratégicamente</span> en la página, resaltando visualmente para que sean fácilmente <span>identificables</span>.
        </p>
        <br />
        <p>
          Al diseñar una CTA efectiva, es recomendable utilizar <span>elementos</span> visuales llamativos, como <span>colores</span> contrastantes, <span>iconos</span> o <span>flechas direccionales</span>, para <span>dirigir</span> la atención del <span>usuario</span> hacia la <span>acción deseada</span>. También se puede complementar la CTA con un <span>mensaje relevante</span> que destaque los beneficios o <span>recompensas</span> de realizar dicha acción.
        </p>
        <br />
        <p>
          Una buena CTA tiene el poder de <span>influir</span> en la tasa de conversión y dirigir el comportamiento del <span>usuario</span>, ayudando a alcanzar los <span>objetivos comerciales</span> y de <span>marketing</span> de un sitio web. Es una <span>herramienta clave</span> para impulsar la <span>interacción</span> y guiar a los <span>visitantes</span> hacia el siguiente paso en su viaje en línea.
        </p>
        <br />
        <div>
          <img src={phoneIsoSVG} alt="mobile-phone-illustration" />
          <h3>
            Y si quiero que mi página se pueda ver desde distintos dispositivos... ¿Es posible?
          </h3>
          <p>
            Si, lo es. Para ello se utiliza el <span><b>diseño web responsive</b></span>, que es un <span><b>enfoque de diseño</b></span> que permite que el <span><b>sitio web</b></span> se adapte <span><b>automáticamente</b></span> al <span><b>dispositivo</b></span> que está utilizando el <span><b>usuario</b></span> para verlo. Esto significa que el <span><b>contenido</b></span> y los <span><b>elementos</b></span> de la página se <span><b>reorganizan</b></span> y <span><b>redimensionan</b></span> según el <span><b>tamaño</b></span> de la <span><b>pantalla</b></span>, para que el <span><b>usuario</b></span> pueda <span><b>ver</b></span> y <span><b>navegar</b></span> por el <span><b>sitio</b></span> de forma <span><b>cómoda</b></span> y <span><b>eficiente</b></span>.
          </p>
          <br />
          <p>
            Al tener un <span>diseño responsive</span>, los sitios web se ven bien y son <span>fáciles de usar</span> en cualquier dispositivo, lo que <span>mejora la navegación</span>, la <span>legibilidad</span> y la <span>interacción</span> con el contenido. Además, los <span>motores de búsqueda</span>, como Google, también <span>valoran los sitios web responsive</span> y los suelen posicionar mejor en los resultados de búsqueda en dispositivos móviles.
          </p>
        </div>
        <br />
        <h3>
          Me lo podrías resumir todo por favor...
        </h3>
        <p>
          En resumen, una <span>landing page</span> es una página web diseñada para convertir visitantes en <span>clientes potenciales</span>, las <span>páginas básicas</span> son elementos esenciales que <span>brindan información clave</span> sobre tu negocio, y el diseño <span>responsive</span> permite que un sitio web se <span>adapte</span> a diferentes <span>dispositivos</span> y tamaños de <span>pantalla</span> para proporcionar una <span>experiencia</span> de usuario <span>óptima</span>.
        </p>
      </div>
      <hr />
      <h3>
        ¿Querés saber sobre otros temas? ¡Seguí bajando!👇
      </h3>
      <p>Te recomendamos que sigas leyendo 😉</p>
      <hr />
      <div>
        <img src={seoIsoSVG} alt="seo-google-illustration" />
        <h3>
          Y como hago que los usuarios encuentren mi página web...🤔
        </h3>
        <p>
          Y acá es cuándo entra el famoso <span>SEO</span> (Search Engine Optimization).
        </p>
        <p>
          El SEO se refiere a las <span>técnicas y estrategias</span> utilizadas para <span>mejorar</span> la visibilidad y el <span>ranking</span> de un <span>sitio web</span> en los motores de búsqueda como <span>Google</span>. El objetivo es <span>atraer tráfico</span> orgánico y <span>relevante</span> al sitio web. Esto implica <span>optimizar</span> aspectos técnicos, como la <span>estructura</span> y velocidad del <span>sitio</span>, así como crear <span>contenido relevante</span> y de <span>calidad</span> que se ajuste a las palabras clave utilizadas por los usuarios en sus búsquedas.
        </p>
      </div>
      <div>
        <img src={hostingIsoSVG} alt="hosting-service-illustration" />
        <h3>
          Muy lindo y todo, pero ¿dónde o cómo "subo" mi página web?
        </h3>
        <p>
          Acá viene lo importante del <span>hosting</span>.
        </p>
        <p>
          El hosting se refiere al <span>servicio</span> que permite <span>almacenar</span> y <span>mostrar</span> tu <span>sitio web</span> en Internet. Un <span>proveedor</span> de hosting <span>ofrece servidores</span> donde se guardan los archivos y datos de tu sitio web. Cuando alguien accede a tu dominio, el <span>servidor</span> del hosting <span>muestra</span> tu sitio web en su <span>navegador</span>. Los <span>servicios</span> de hosting varían en términos de <span>capacidad</span>, <span>seguridad</span>, <span>soporte técnico</span> y <span>precio</span>. Es importante elegir un servicio de hosting confiable que se ajuste a las necesidades de tu sitio web.
        </p>
      </div>
      <div>
        <img src={domainIsoSVG} alt="domain-service-illustration" />
        <h3>
          Bien, entiendo pero ¿Qué es eso del "dominio"? ¿Es lo mismo que el hosting? ¿Con eso puedo tener una URL personalizada?
        </h3>
        <p>
          Algo así... pero antes dejame aclarar tus preguntas sobre el <span>dominio</span> y su relación con el hosting, así como su capacidad para tener una URL personalizada.
        </p>
        <p>
          Un <span>dominio</span> web es la <span>dirección única</span> y legible por humanos que <span>utilizás</span> para acceder a un sitio web, como por ejemplo "www.mipagina.com". Los <span>dominios</span> web son una forma de <span>identificar</span> y ubicar <span>sitios web</span> en Internet. Cada dominio debe ser <span>registrado</span> y se compone de dos partes principales: el nombre del dominio (por ejemplo, "mipagina") y la extensión de dominio (como ".com", ".org" o ".net").
        </p>
        <br />
        <p>
          El <span>hosting</span>, por otro lado, se refiere al <span>servicio</span> que permite <span>almacenar</span> y <span>mostrar</span> tu sitio web en Internet. El hosting proporciona los servidores donde se <span>guardan los archivos</span> y <span>datos</span> de tu sitio web (como ya expliqué arriba). Cuando alguien <span>accede a tu dominio</span>, el servidor del hosting <span>muestra</span> tu sitio web en su <span>navegador</span>. El hosting y el dominio <span>son dos servicios diferentes pero relacionados que se necesitan mutuamente para que un sitio web sea accesible en línea.</span>
        </p>
        <br />
        <p>
          Ahora bien, respecto a tener una <span>URL personalizada</span>, sí, el dominio web <span>te permite tener</span> una URL personalizada. Al registrar un <span>dominio</span>, podés elegir un <span>nombre único</span> para tu <span>sitio web</span>, como por ejemplo "www.tunombre.com". Esto te <span>brinda una identidad</span> en línea propia y <span>profesional</span>.
        </p>
        <br />
        <p>
          Es importante <span>tener en cuenta</span> que el dominio y el hosting son dos <span>servicios diferentes</span> y debes <span>adquirirlos por separado</span>. Podés registrar un dominio con proveedores de servicios de dominio, y también podés contratar un servicio de hosting para almacenar y mostrar tu sitio web en internet.
        </p>
        <br />
        <h3>
          ¿Me hacés un resumen de nuevo? Por fa 🥺
        </h3>
        <p>
          Ugghh, si...
        </p>
        <br />
        <p>
          En resumen, un <span>dominio web</span> es la <span>dirección única</span> que utilizás para <span>acceder a un sitio web</span>, mientras que el <span>hosting</span> es el <span>servicio</span> que te <span>permite almacenar</span> y mostrar tu <span>sitio web</span> en Internet. A través del dominio, podés tener una URL personalizada que <span>brinda identidad</span> a tu sitio web y permite a los usuarios <span>encontrarte fácilmente</span> en línea.
        </p>
      </div>
      <p>
        Si, ya sé, mucho texto... Pero por haber llegado hasta acá te dejo un 20% de descuento en tu primer página que hagas con <span>El Mirador Web</span>.
        Se festeja en el obelisco 🎉🥳.
      </p>
      <div className={style.btn__footer} id="btn">
        <a href="#header"><FontAwesomeIcon icon={faArrowTurnUp} className={style.arrow} /></a>
      </div>
    </section>
  )
}

export default AboutMore
