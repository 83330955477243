import React from 'react'
import style from '../assets/scss/Main.module.css'
import Head from '../components/Head'
import Content from '../components/Content'
import MiddleContent from '../components/MiddleContent'
import Footer from '../components/Footer'

function Main() {
  return (
    <main className={style.main}>
      <Head />
      <Content />
      <MiddleContent />
      <Footer />
    </main>
  )
}

export default Main