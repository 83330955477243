import React from 'react'
import style from '../assets/scss/Content.module.css'
import accessAnywhereIcon from '../assets/images/icon-access-anywhere.svg'
import anyFileIcon from '../assets/images/icon-any-file.svg'
import collabIcon from '../assets/images/icon-collaboration.svg'
import securityIcon from '../assets/images/icon-security.svg'

function Content() {

  const content = [
    {
      id: 1,
      icon: accessAnywhereIcon,
      title: 'Diseño personalizado',
      info: 'El diseño es una parte fundamental de cualquier página web efectiva. Podemos trabajar con vos para crear un diseño atractivo y coherente con la identidad de tu marca.',
    },
    {
      id: 2,
      icon: anyFileIcon,
      title: 'Desarrollo de contenido',
      info: 'El contenido de una web es igualmente importante y debe ser convincente y persuasivo. Podemos ayudarte para que puedas ofrecer un contenido relevante y persuasivo para tu página.',
    },
    {
      id: 3,
      icon: collabIcon,
      title: 'Optimización para motores de búsqueda (SEO)',
      info: 'Para asegurar que tu página tenga la mayor visibilidad posible en los buscadores, se puede trabajar en su optimización para motores de búsqueda. Esto incluye la optimización de títulos, etiquetas meta, contenido y otras tácticas SEO.'
    },
    {
      id: 4,
      icon: securityIcon,
      title: 'Integración con herramientas de marketing',
      info: 'Para mejorar la conversión de leads a clientes potenciales y clientes reales, se pueden integrar herramientas de marketing en tu página, como formularios de suscripción, CTA (Call to Action) y herramientas de seguimiento de visitantes.'
    }
  ]

  return (
    <section className={style.section}>
      {
        content.map((item) => {
          return (
            <div key={item.id} className={style.section__container}>
              <img src={item.icon} alt={item.title} className={style.container__icon} />
              <div className={style.container__info}>
                <h3 className={style.info__title}>{item.title}</h3>
                <p className={style.info__text}>{item.info}</p>
              </div>
            </div>
          )
        })
      }
    </section>
  )
}

export default Content