import React from 'react'
import style from '../assets/scss/Nav.module.css'
import Logo from '../assets/images/mirador-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { HashLink as Link } from 'react-router-hash-link'

function Header() {

  const navItems = [
    {
      title: 'Inicio',
      link: '/#header'
    },
    {
      title: '¿Quiénes somos?',
      link: '/about#header'
    },
    {
      title: 'Saber más',
      link: '/about/more#header'
    },
    {
      title: 'Servicios',
      link: '/services#header'
    },
    {
      title: 'Términos y Condiciones',
      link: '/terminos-y-condiciones#header'
    },
  ]

  const toggle = () => {
    const menuMobile = document.querySelector(`.${style.navbar__menuMobile}`)
    menuMobile.classList.toggle(`${style.show}`)
  }

  return (
    <header className={style.header} id='header'>
      <nav className={style.header__navbar}>
        {/* menu mobile */}
        <figure className={style.icon__figure} onClick={toggle}>
          <FontAwesomeIcon icon={faBars} fontSize={30} />
        </figure>
        <ul className={style.navbar__menuMobile} onClick={toggle}>
          <div className={style.list}>
            {
              navItems.map((item, index) => {
                return (
                  <li className={style.item} key={index}>
                    <a href={item.link} className={style.link}>{item.title}</a>
                  </li>
                )
              })
            }
            <figure className={style.logo}>
              <img src={Logo} alt="El mirador web - Logo" className={style.logo} width={100} />
            </figure>
          </div>
        </ul>
        {/* menu desktop */}
        <div className={style.header__navbarDesktop}>
          <ul className={style.navbarDesktop__menu}>
            {
              navItems.map((item, index) => {
                return (
                  <li className={style.item} key={index}>
                    <Link to={item.link} className={style.link}>{item.title}</Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header