import React from 'react'
import style from '../assets/scss/Footer.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import logo from '../assets/images/mirador-logo.svg'
import msgIcon from '../assets/images/NewMessageFlatline.svg'

function Footer() {
  return (
    <footer className={style.footer} id='contacto'>
      <div className={style.footer__msg}>
        <img src={msgIcon} alt="messagge-icon-illustration" />
      </div>
      <div className={style.footer__contact}>
        <h3 id='gmail'>Contactanos ya mismo</h3>
        <p>
          ¿Querés llevar tu negocio al siguiente nivel en una web? ¡No esperes más y contáctanos ya mismo! Simplemente envíanos un correo demostrando tu idea y te responderemos lo antes posible.
        </p>
        <a href="mailto:contacto@elmiradorweb.com.ar">Contanos tu idea!</a>
      </div>
      <div className={style.footer__icons}>
        <a href="#header" className={style.icons__logo}>
          <img src={logo} alt="El mirador web - Logo" width={100} />
        </a>
        <div className={style.icons__social}>
          <a href="https://api.whatsapp.com/send?phone=5493415873097&text=Hola!%20Quisiera%20saber%20m%C3%A1s%20sobre%20sus%20servicios" target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faWhatsapp} className={style.footer__icon} fontSize={25} width={25} />
          </a>
          <a href="https://www.linkedin.com/company/el-mirador-web/about/" target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faLinkedin} className={style.footer__icon} fontSize={25} width={25} />
          </a>
          <a href="mailto:contacto@elmiradorweb.com.ar">
            <FontAwesomeIcon icon={faEnvelope} className={style.footer__icon} fontSize={25} width={25} />
          </a>
        </div>
        <span className={style.copy}>Leer sobre los <a style={{ marginRight: '.5rem' }} href="/terminos-y-condiciones#header" rel='noreferrer'>Términos y condiciones</a></span>
      </div>
    </footer>
  )
}

export default Footer