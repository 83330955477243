import React from 'react'
import style from '../assets/scss/Services.module.css'
import { HashLink as Link } from 'react-router-hash-link'

function Services() {

  var headTitle = document.head

  var newHead = document.createElement('title')
  newHead.innerHTML = 'El Mirador - Servicios que ofrecemos'

  var oldHead = headTitle.querySelector('title')
  oldHead ? headTitle.removeChild(oldHead) : headTitle.appendChild(newHead)

  return (
    <section className={style.services}>
      <div className={style.services__readText}>
        <h4>Antes de elegir un servicio, <span>lee lo siguiente</span>:</h4>
        <br />
        <p>
          En El Mirador, nos especializamos en el desarrollo de páginas web estáticas. ¿Qué significa esto? Significa que diseñamos y construimos sitios web que no requieren una plataforma de administración compleja o un sistema de gestión de contenido (CMS) para realizar cambios en el contenido.
        </p>
        <br />
        <p>
          Nuestro enfoque en las páginas web estáticas se basa en las necesidades y objetivos de nuestros clientes. En lugar de utilizar un CMS, construimos cada página web de forma personalizada y cuidadosa, asegurándonos de que cada elemento esté en su lugar correcto y que el diseño sea estéticamente atractivo y funcional.
        </p>
        <br />
        <p>
          La elección de páginas web estáticas en lugar de páginas autoadministrables puede ser beneficioso en ciertos casos. Si tu sitio web no requiere actualizaciones frecuentes de contenido, como noticias o blogs, o si prefieres dejar las actualizaciones en manos de profesionales de diseño web, una página estática puede ser la opción ideal.
        </p>
        <br />
        <p>
          Algunas ventajas de las páginas web estáticas incluyen:
        </p>
        <ul>
          <li><span>Mayor seguridad</span>: Al no tener un sistema de administración en línea, las páginas estáticas tienen una superficie de ataque reducida y son menos susceptibles a ataques cibernéticos.</li>
          <li><span>Rendimiento optimizado</span>: Las páginas estáticas tienden a cargar más rápido, ya que no hay procesamiento dinámico de datos ni consultas a una base de datos.</li>
          <li><span>Mayor control sobre el diseño</span>: Al construir páginas web estáticas a medida, tenemos un control total sobre el diseño y la apariencia de cada elemento, lo que nos permite crear una experiencia única y personalizada para tus visitantes.</li>
          <li><span>Menor complejidad técnica</span>: Al no requerir un CMS, las páginas web estáticas son más simples y fáciles de mantener. Esto también puede significar menos costos a largo plazo en términos de actualizaciones y soporte técnico.</li>
        </ul>
        <p>
          Es importante destacar que si en el futuro deseas realizar cambios o actualizaciones en el contenido de tu sitio web, podemos brindarte servicios adicionales para realizar esas modificaciones de manera rápida y eficiente. Nuestro equipo estará encantado de ayudarte en cualquier momento.
        </p>
        <br />
        <p>
          En resumen, en El Mirador nos especializamos en la creación de páginas web estáticas. Esto nos permite enfocarnos en ofrecerte diseños personalizados, mayor seguridad, rendimiento optimizado y un mayor control sobre el aspecto visual de tu sitio web. Si buscas un sitio web sin la necesidad de actualizaciones frecuentes de contenido o si prefieres dejar las actualizaciones en manos de profesionales, las páginas web estáticas son la opción perfecta para ti.
        </p>
      </div>
      <br />
      <h3><span>Servicios</span> que ofrecemos</h3>
      <ul className={style.services__list}>
        <li>
          <h4 className={style.a}><span><Link to='/about/more#landpages'>#Landing Pages</Link></span></h4>
          <p>
            En El Mirador, ofrecemos servicios de desarrollo y maquetado de landing pages efectivas. Sabemos que una landing page bien diseñada puede marcar la diferencia en la generación de clientes potenciales. Creamos landing pages personalizadas que capturan la atención de los visitantes y los motivan a realizar una acción específica, ya sea una compra, una suscripción o completar un formulario. Nuestro enfoque se basa en la combinación de diseño atractivo, contenido persuasivo y <Link to="/about/more#ctas" className={style.a}><span>#CTAs</span></Link> impactantes para maximizar la tasa de conversión y el retorno de la inversión.
          </p>
        </li>
        <li>
          <h4 className={style.a}><span><Link to='/about/more#basicpage'>#Páginas Básicas</Link></span></h4>
          <p>
            En El Mirador, también ofrecemos servicios de desarrollo de páginas básicas para tu sitio web. Creamos páginas como la página de inicio, página de servicios, página de productos y página de contacto, entre otras. Nuestro enfoque se centra en brindar información clara y concisa sobre tu negocio y tus ofertas. Diseñamos páginas atractivas y fáciles de navegar, que reflejen la identidad de tu marca y resalten los aspectos más importantes de tu negocio. Nuestro objetivo es crear una experiencia de usuario satisfactoria y transmitir el mensaje adecuado a tus visitantes.
          </p>
        </li>
        <li>
          <h4><span>Remodelación de Páginas</span></h4>
          <p>
            ¿Tenés un sitio web existente que necesita un cambio? En El Mirador, también ofrecemos servicios de remodelación de páginas. Si tu sitio web actual se ve desactualizado, tiene un diseño poco atractivo o no cumple con tus necesidades comerciales actuales, estamos acá para ayudarte. Evaluaremos tu sitio y te brindaremos soluciones personalizadas para mejorar su apariencia, funcionalidad y rendimiento. Ya sea que necesites una renovación completa o pequeñas mejoras, trabajaremos con vos para lograr un sitio web moderno y atractivo que cumpla con tus objetivos.
          </p>
        </li>
      </ul>
    </section>
  )
}

export default Services
