import React from 'react'
import style from '../assets/scss/About.module.css'
import questionMarkSVG from '../assets/images/questionFlatline.svg'
import landingFlat1 from '../assets/images/LandingPageFlatline_1.svg'
import landingFlat2 from '../assets/images/LandingPageFlatline_2.svg'
import personalData from '../assets/images/PersonalDataMonochromatic.svg'

function About() {

  var headTitle = document.head

  var newHead = document.createElement('title')
  newHead.innerHTML = 'El Mirador - Sobre Nosotros'

  var oldHead = headTitle.querySelector('title')
  oldHead ? headTitle.removeChild(oldHead) : headTitle.appendChild(newHead)

  return (
    <section className={style.about}>
      <div className={style.cards}>
        <h3><span>¿Y quiénes son ustedes?</span> te estarás preguntando...</h3>
        <div className={style.illustration}>
          <img src={questionMarkSVG} alt="illustration-question-svg" />
        </div>
        <div className={style.card}>
          <p>
            <span><b>El Mirador</b></span> es un proyecto impulsado por dos <span><b>hermanos emprendedores</b></span> que están dando sus primeros pasos en el mundo del <span><b>desarrollo y maquetado web.</b></span>
          </p>
          <p>
            Con un enfoque fresco y una pasión por la <span><b>creatividad</b></span>, nos esforzamos por ofrecer <span><b>soluciones</b></span> web personalizadas y <span><b>efectivas para empresas</b></span> de todos los tamaños y sectores.
          </p>
        </div>
      </div>
      <div className={style.cards}>
        <h3>Bueno, pero... <span>¿Qué</span> es lo que <span>hacen exactamente?</span></h3>
        <div className={style.illustrations}>
          <img src={landingFlat2} alt="illustration-landing-page-flat1-svg" className={style.deskSvg} />
          <img src={landingFlat1} alt="illustration-landing-page-flat2-svg" className={style.mobileSvg} />
        </div>
        <div className={style.card}>
          <p>
            Nuestro objetivo principal es <span><b>ayudar</b></span> en el diseño, <span><b>desarrollar</b></span> y <span><b>actualizar</b></span> sitios web que reflejen la <span><b>identidad de marca</b></span> de nuestros clientes y cumplan con sus <span><b>objetivos comerciales</b></span>. Aunque estamos en las primeras etapas de nuestro emprendimiento, nos enorgullece ofrecer <span><b>soluciones</b></span> web que <span><b>destaquen</b></span> y generen <span><b>resultados</b></span>🥳.
          </p>
          <p>
            Valoramos la <span><b>colaboración</b></span> y la <span><b>comunicación</b></span> transparente. Nos esforzamos por establecer relaciones de <span><b>confianza</b></span> con vos, nuestro cliente, <span><b>involucrándote</b></span> en cada etapa del proceso de desarrollo web.
          </p>
        </div>
      </div>
      <div className={style.cards}>
        <h3>
          ¿Qué nos hace<span> diferentes?</span>
        </h3>
        <div className={style.illustration}>
          <img src={personalData} alt="illustration-svg" />
        </div>
        <div className={style.card}>
          <p>
            En <span><b>El Mirador</b></span> nos comprometemos a brindarte una <span><b>experiencia</b></span> personalizada y de <span><b>calidad</b></span>. Siendo una empresa en crecimiento, ofrecemos <span><b>precios competitivos</b></span> sin comprometer la calidad y la dedicación que ponemos en cada proyecto.
          </p>
          <p>
            ¡Permítenos <span><b>ser parte de tu viaje</b></span> en línea! Contáctanos hoy mismo y déjanos ayudarte a construir una <span><b>presencia</b></span> en línea <span><b>sólida</b></span> y <span><b>atractiva</b></span> para tu negocio. Juntos, podemos alcanzar <span><b>grandes logros</b></span> en el mundo digital.
          </p>
        </div>
      </div>
      <article className={style.resume}>
        <p>
          En El Mirador nos <span><b>diferenciamos</b></span> por nuestra <span><b>creatividad</b></span>, <span><b>enfoque</b></span> personalizado, <span><b>comunicación</b></span> transparente, enfoque en la <span><b>experiencia</b></span> del usuario y <span><b>pasión</b></span> por la <span><b>calidad</b></span>.
        </p>
        <p>
          Estamos acá para hacer que tu <span><b>presencia</b></span> en línea sea <span><b>única</b></span>, <span><b>memorable</b></span> y <span><b>exitosa</b></span>. ¡Permítenos mostrar cómo podemos marcar la diferencia para tu negocio en el mundo digital!
        </p>
      </article>
    </section>
  )
}

export default About